import React from "react"
import styled from "styled-components"
import { getImage, GatsbyImage } from "gatsby-plugin-image"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Header from "../components/Header"
import {
  Section,
  Container,
  SectionPageTitle,
  MarkdownContent,
  Background,
  BreadCrumb,
  Flex,
  FlexTwoCol,
  FlexVwCol,
} from "../components/SectionTags"
import HeroBg from "../components/Image/IndustryHeroBg"
import CallToAction from "../components/CallToAction"
import Content from "../components/Content"
import ArrowIcon from "../components/Icons/ArrowIcon"
import BreakpointUp from "../components/Media/BreakpointUp"

const Sections = styled(Section)`
  &:before{
    height:50%;
    ${BreakpointUp.md`
      height:60%;
    `}
  }
`

const ProductMedia = styled.div`
  text-align:left;
  margin-bottom: 30px;
  ${BreakpointUp.md`
    text-align:right;
    margin-bottom: 0;
  `}
`
const OffersSection = styled.div`
  ${BreakpointUp.xl`
    padding-right:calc(100% - 1350px);
  `}
`
const OffersContentWrap = styled.div`
  position: relative;
  background: white;
  box-shadow: 0 15px 65px rgb(0 0 0 / 15%);
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  padding: 30px;
  ${BreakpointUp.lg`
    padding: 60px;
    padding-left: calc(100% - 990px);
  `}
  ${BreakpointUp.xl`
    padding: 80px;
    padding-left: calc(100% - 1160px);
  `}
`
const OffersTitle = styled.h2`
  margin-bottom:0;
  @media (min-width: 567px) {
    font-size: 32px;
    line-height: 44px;
  }
  @media (min-width: 992px) {
    font-size: 44px;
    line-height: 58px;
  }
`

const VerticalTitle = styled.div`
  position: absolute;
  top: 50%;
  font-size: 50px;
  color: rgba(51,51,51,0.1);
  height: 50px;
  transform-origin: 0 0;
  transform: rotate(90deg) translateX(0%);
  left: calc(100% - 25px);
  white-space: nowrap;
  /* display:none; */
`
const Offer = styled.div`
  border-bottom: 1px solid #dedede;
  padding: 20px 0;   
  @media (min-width: 768px) {
    padding: 30px 0;
  }
`
const ArrowPoints = styled.div`
  display: grid;
  flex-wrap: wrap;
  color: #000;
  grid-template-columns: repeat(1, 1fr);
  margin: 0 -10px;
  ${BreakpointUp.sm`
    grid-template-columns: repeat(2, 1fr);
  `}
  ${BreakpointUp.xl`
    grid-template-columns: repeat(3, 1fr);
  `}
`
const ArrowPointsItem = styled.div`
  padding:10px;
  & a {
    font-size: 16px;
    line-height: 26px;
    font-family: 'UniNeueBold', sans-serif;
    font-weight: 700;
    display:flex;
    flex-wrap:nowrap;
    color:#000;
    & .icon{
      & svg {     
        transition: transform ease 0.2s;
      }
      + .text{
        margin-left: 10px;
      }
    }
    &:hover {
      color:#fab702;
      .icon{
        & svg {
          transform: translateX(5px);
        }
      }
    }
  }
`

class PartnerTemplate extends React.Component {
  constructor(props) {
    super(props)
    this.renderFeatures = this.renderFeatures.bind(this)
  }
  renderFeatures(data) {
    return (
      <ArrowPoints>
        {data.map((item, i) => {
          // const temp = service.replace(/[^\w\s]+/gi, " ").replace(/  +/g, " ")
          // const serviceUrl = temp.split(" ").join("-").toLowerCase()
          // const itemTemp = item.content
          //   .replace(/[^\w\s]+/gi, " ")
          //   .replace(/  +/g, " ")
          // const itemUrl = itemTemp.split(" ").join("-").toLowerCase()

          return (
            <ArrowPointsItem key={i}>
              <Link to={`/${item.url}`}>
                <span className="icon"><ArrowIcon fill="#FAB702" /></span>
                <span className="text">{item.name}</span>
              </Link>
            </ArrowPointsItem>
          )
        })}
      </ArrowPoints>
    )
  }
  render() {
    const contentData = this.props.data.contentfulPartner
    return (
      <Layout>
        <Seo title={contentData.metaTitle}
          description={contentData.metaDescription} />
        <div className="sticky-wrapper sticky">
          <Header />
        </div>
        <Sections xpt="120px" xpb="100px" mpt="120px" mpb="80px" pt="90px" pb="60px"
        bgBeforeWidth="100%"
        topAfter="0"
        bgBefore="linear-gradient(to bottom,#ffffff 0%, #EDF8FF 100%)"
        >
          <Background height="auto" right="0"><HeroBg /></Background>
          <Container maxWidth="1120px">
            <BreadCrumb>
              <Link to="/"><span>Home</span></Link> {contentData.name}
            </BreadCrumb>
            <Flex alignItem="center" justifyContent="space-between">
              <FlexTwoCol flexWidth="66.666667%" flexMWidth="66.666667%">
                <SectionPageTitle>{contentData.title}</SectionPageTitle>
                <MarkdownContent
                  dangerouslySetInnerHTML={{
                    __html: contentData.description.childMarkdownRemark.html,
                  }}
                />
              </FlexTwoCol>
              <FlexVwCol>
                <ProductMedia>
                  <GatsbyImage image={getImage(contentData.logo)} alt="brand" />
                </ProductMedia>
              </FlexVwCol>
            </Flex>
          </Container>  
          <OffersSection>
            <VerticalTitle>Repair Services</VerticalTitle>
            <Container maxWidth="100%" pl="0" pr="0">
              <OffersContentWrap>
                <Background height="auto" left="0px"><HeroBg /></Background>
                <Flex alignItem="center" justifyContent="space-between">
                  <FlexTwoCol flexWidth="33.333333%" flexMWidth="33.333333%">
                    <OffersTitle>{contentData.offerSectionTitle}</OffersTitle>
                  </FlexTwoCol>
                  <FlexTwoCol flexWidth="66.666667%" flexMWidth="66.666667%">
                    {contentData.supportedService.map((item, i) => {
                      return (
                        <Offer key={i}>
                          <h3>{item.service.serviceName}</h3>
                          {this.renderFeatures(item.service.service_sub_repair)}
                        </Offer>
                      )
                    })}
                  </FlexTwoCol>
                </Flex>
              </OffersContentWrap>
            </Container>
          </OffersSection>
          </Sections>
        <CallToAction data={contentData.cta} />
        <Content
          data={contentData.content}
          video={contentData.contentRightVideo}
        />
      </Layout>
    )
  }
}

export default PartnerTemplate

export const pageQuery = graphql`
  query partnerQuery($id: String!) {
    contentfulPartner(id: { eq: $id }) {
      metaTitle
      metaDescription {
        metaDescription
      }
      name
      description {
        childMarkdownRemark {
          html
        }
      }
      logo {
        gatsbyImageData(quality: 100)
      }
      offerSectionTitle
      supportedService {
        url
        service {
          url
          serviceName
          features {
            content
          }
          service_sub_repair {
            url
            name
          }
        }
      }
      cta {
        label
        title
        linkText
      }
      content {
        childMarkdownRemark {
          html
        }
      }
      contentRightVideo
    }
  }
`
